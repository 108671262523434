import { useEffect, useRef } from 'react';
import CustomButton from '../CustomButton';
import DialogPropInterface from '../DialogPropsInterface';
import './styles.css';

type Props  = {
    onProceed: () => void;
    onShouldReset: () => void;
    children: React.ReactNode;
} & DialogPropInterface;

export default function ({
    isOpened,
    onClose,
    onProceed,
    onShouldReset,
    children,
}: Props) {
    const ref: any = useRef(null);

    useEffect(() => {
        if (isOpened) {
            ref.current?.showModal();
            document.body.classList.add("modal-open"); // prevent bg scroll
        } else {
            ref.current?.close();
            document.body.classList.remove("modal-open");
        }
    }, [isOpened]);

    const proceedAndClose = () => {
        onProceed();
        onClose();
    };

    const preventAutoClosing = (e: React.MouseEvent) => e.stopPropagation();

    return (
        <dialog ref={ref} onCancel={onClose} onClick={onClose}>
            <div className="dialogContent" onClick={preventAutoClosing}>
                {children}
                <div className='buttonContainer'>
                    <CustomButton id="dialogDoneButton" type='btnPrimary' onClick={proceedAndClose} title="Use Filter"/>
                    <CustomButton id="dialogCancelButton" type='btnCancel' onClick={onClose} title="Cancel"/>
                    <CustomButton id="dialogResetButton" type='btnCancel' onClick={onShouldReset} title="Reset Query"/>
                </div>
            </div>
        </dialog>
    );
}