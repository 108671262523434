import './styles.css';
import { Company, Skill } from '../types';

type PropsType = {
    i: number;
    company: Company;
}
export default function ({ i, company }: PropsType ) {

    const projectSkills: Skill[][] = company.projects
        ?.map(p => p.skills ?? []) ?? [];
    
    const flatProjeftSkills = projectSkills.reduce((result, item) => {
        
        return [...result, ...item.filter(i => !result.map(j => j.name).includes(i.name))];
    }, []);

    const companySkills = company.skills ?? flatProjeftSkills;
    const showSkills = companySkills.length > 0;
    const cardBody = <>
        <header>
            <h4>{company.name || '?'}</h4>
            {company.numberOfMonths && <i>{company.numberOfMonths} months</i>}
        </header>
        {company.projects && <div>
            <strong>Projects: </strong>
            {company.projects && (
                <p className='tagContainer'>
                    {company.projects.map((p, i) => (
                        <span className="tag" key={`${p.name}_${i}`}>
                            {p.name}{i < ((company.projects || []).length - 1) ? ',\u00A0 ' : ''}
                        </span>
                    ))}
                    {(company.projects || []).length === 0 && <span>No public projects to show</span>}
                </p>
            )}
            
        </div>}
        {showSkills && <div>
            <strong>Skills: </strong>
            {companySkills && (
                <p className="tagContainer">
                    {companySkills.map((s, i) => (
                        <span className="tag" key={`${s.name}_${i}`}>
                            {s.name}{i < ((companySkills || []).length - 1) ? ',\u00A0 ' : ''}
                        </span>
                    ))}
                </p>
            )}
        </div>}
    </>;
    

    return (company.url
        ? <a className="card" href={company.url}>{cardBody}</a>
        : <div className="card">{cardBody}</div>
    );
}