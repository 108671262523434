import { createContext, createRef, MouseEventHandler, PropsWithChildren, TouchEventHandler, useCallback, useContext, useState } from "react";


type MouseAnimationViewModel = {
    onMouseMove: MouseEventHandler<HTMLDivElement>
    onTouchMove: TouchEventHandler<HTMLDivElement>;
    imgRef: React.RefObject<HTMLImageElement>;
};
const emptyMouseAnimationViewModel = {
    onMouseMove: () => {},
    onTouchMove: () => {},
    imgRef: createRef<HTMLImageElement>(),
}
export const MouseAnimationContext = createContext<MouseAnimationViewModel>(emptyMouseAnimationViewModel);

export function useMouseAnimationContext(): MouseAnimationViewModel {
    return useContext(MouseAnimationContext)
}

export function MouseAnimationProvider({ children }: PropsWithChildren) {

    const imgRef = createRef<HTMLImageElement>();

    function transforms(x: number, y: number, el: HTMLImageElement) {
        const constrain = 100;
        const absLimit = 7.5;
        const box = el.getBoundingClientRect();
        const _calcX = -(y - box.y - (box.height / 2)) / constrain;
        const _calcY = (x - box.x - (box.width / 2)) / constrain;
        const calcX = Math.sign(_calcX) * Math.min(Math.abs(_calcX), absLimit);
        const calcY = Math.sign(_calcY) *  Math.min(Math.abs(_calcY), absLimit);
        
        return "perspective(100px) "
            + "   rotateX("+ calcX +"deg) "
            + "   rotateY("+ calcY +"deg) ";
    };

    function transformElement(x: number, y: number, el: HTMLImageElement) {
        el.style.transform = transforms(x, y, el);
    }

    const onMouseMove: MouseEventHandler<HTMLDivElement> = (e) => {    
        if (imgRef.current) {
            transformElement(e.clientX, e.clientY, imgRef.current)
        }
    }

    const onTouchMove: TouchEventHandler<HTMLDivElement> = (e) => {
        if (imgRef.current) {
            transformElement(e.touches[0].clientX, e.touches[0].clientY, imgRef.current)
        }
    }

    const viewModel = {
        onMouseMove,
        onTouchMove,
        imgRef,
    };

    return <MouseAnimationContext.Provider value={viewModel}>
        {children}
    </MouseAnimationContext.Provider> 
}