import "./styles.css";
import { useEffect, useRef } from 'react';
import CustomButton from '../CustomButton';
import './styles.css';
import DialogPropInterface from "../DialogPropsInterface";

type Props = {
    error: Error;
    title: string;
    message: string;
    onOKPressed: () => void;
} & DialogPropInterface;

export default function ErrorDialog({
    isOpened,
    title,
    message,
    onClose,
    onOKPressed,
}: Props) {
    const ref: any = useRef(null);

    useEffect(() => {
        if (isOpened) {
            ref.current?.showModal();
            document.body.classList.add("modal-open"); // prevent bg scroll
        } else {
            ref.current?.close();
            document.body.classList.remove("modal-open");
        }
    }, [isOpened]);

    const okAndClose = () => {
        onOKPressed();
        onClose();
    };

    const preventAutoClosing = (e: React.MouseEvent) => e.stopPropagation();

    return (
        <dialog ref={ref} onCancel={onClose} onClick={onClose}>
            <div className="dialogContent" onClick={preventAutoClosing}>
                <h4>{title}</h4>
                <p>{message}</p>
                <div className='buttonContainer'>
                    <CustomButton id="dialogOKButton" type='btnPrimary' onClick={okAndClose} title="OK"/>
                </div>
            </div>
        </dialog>
    );
}