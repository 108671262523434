import { createContext, PropsWithChildren, useContext, useEffect, useState } from "react";
import { BlogPost, Company, Project, QueryResponse, Skill } from "./types";

const kGraphQLURL = 'https://us-central1-forritarinn-567c2.cloudfunctions.net/graphql';

function makeid() {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < 32) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
    return result;
}

const kInitialGraphQL = `
    query {
        # Example: To only include companies where I made use of the skills
        #          TypeScript, JavaScript, React or Frontend Web Development:
        #
        #          companies(skillNames:["TypeScript", "JavaScript", "React", "Frontend Web Development"])
        # 
        # Example: Filter by name:
        #          
        #          companies(name:"Memento")
        companies {
            name,
            url,
            numberOfMonths,
            projects {
                id,
                name,
            }
            skills {
                name
            }
        }

        # Example: Filter by skill: \`projects(skillNames:["TypeScript"])\`

        projects {
            id,
            name,
            url
            skills { name }
        }
        blogPosts {
            name,
            url,
            skills {
                name,
                type
            }
        }
        skills {
            name,
            type
        }
    }
`;

const constructGraphiQLURL = (query: string): string => {
    return `https://us-central1-forritarinn-567c2.cloudfunctions.net/graphql?query=${encodeURIComponent(query)}`
}

type APIViewModel = {
    isLoading: boolean;
    error?: Error;

    fetchData: () => void;

    query: string;
    setQuery: (query: string) => void;
    resetGraphQLQuery: () => void;
    graphiQLURL: string;

    companies: Company[],
    projects: Project[],
    skills: Skill[],
    blogPosts: BlogPost[],

};

const emptyAPIViewModel: APIViewModel = {
    isLoading: true,
    fetchData: () => {},
    resetGraphQLQuery: () => {},
    query: kInitialGraphQL,
    setQuery: (_) => {},
    graphiQLURL: constructGraphiQLURL(kInitialGraphQL),

    companies: [],
    skills: [],
    projects: [],
    blogPosts: [],
};

export const APIContext = createContext<APIViewModel>(emptyAPIViewModel);

export function useAPI(): APIViewModel {
    return useContext(APIContext)
}


export function APIProvider({ children }: PropsWithChildren) {

    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState<Error>();
    
    const [queryResponse, setQueryResponse] =  useState<QueryResponse>();

    const [requestTrigger, setRequestTrigger] = useState(makeid());

    const [query, setQuery] = useState<string>(kInitialGraphQL);

    const fetchData = () => {
        setRequestTrigger(makeid());
    }

    const resetGraphQLQuery = () => {
        setQuery(kInitialGraphQL);
    }

    useEffect(() => {
        const _fetch = async () => {

            setError(undefined);
            setIsLoading(true);

            const requestHeaders: HeadersInit = new Headers();
            requestHeaders.set('Content-Type', 'application/json');
    
            const requestInit: RequestInit = {
                method: "POST",
                mode: "cors",
                headers: requestHeaders,
                body: JSON.stringify({
                    query,
                })
            }

            const res = await fetch(kGraphQLURL, requestInit);
            if(!res.ok) {
                const err = new Error('Failed to make GraphQL request.');
                setError(err);
            } else {
                const jsonData = await res.json();
                if (jsonData.data) {
                    setQueryResponse(jsonData)
                }
            }
        };

        _fetch()
            .then()
            .catch(err => {
                if (err instanceof Error) {
                    setError(err);
                }
            })
            .finally(() => setIsLoading(false))
            
    }, [requestTrigger])


    const viewModel: APIViewModel = {
        isLoading,
        
        fetchData,
        
        companies: queryResponse?.data?.companies ?? [],
        projects: queryResponse?.data?.projects ?? [],
        skills: queryResponse?.data?.skills ?? [],
        blogPosts: queryResponse?.data?.blogPosts ?? [],


        query,
        setQuery,
        resetGraphQLQuery,
        graphiQLURL: constructGraphiQLURL(query),
        error,
    }

    return <APIContext.Provider value={viewModel}>
        {children}
    </APIContext.Provider>
}