import './styles.css';
import { Skill } from '../types';

export default function SkillComponent({ skill, }: { skill: Skill }) {
    return <div className="card skill">
        <img onError={(event) => {
            (event.target as HTMLImageElement).style.display = 'none';
        }} src={encodeURIComponent(`/stack-icons/${(skill.name || '').toLowerCase()}.svg`)}/>
        <h4>
            {skill.name || '?'}
        </h4>
    </div>
}