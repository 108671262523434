import { BlogPost } from '../types';

export default function BlogPostComponent({i, blog}: {i: number, blog: BlogPost}) {
    const cardBody = <>
        <h4>
            {blog.name || '?'}
        </h4>
        {blog.skills && <div>
            <strong>Skills: </strong>
            {blog.skills && (
                <div className="tagContainer">
                    {blog.skills.map((s, i) => (
                        <div className="tag" key={`${s.name}_${i}`}>{s.name}{i < ((blog.skills || []).length - 1) ? ',\u00A0 ' : ''}</div>
                    ))}
                </div>
            )}
        </div>}
    </>
    return (
        blog.url
        ? <a key={`${blog.name}_${i}`} className="card" href={blog.url}>{cardBody}</a>
        : <div key={`${blog.name}_${i}`} className="card" >{cardBody}</div>
    );
}