import './styles.css';
import { Link, LinkProps, useMatch, useResolvedPath } from 'react-router-dom';

function NavBarLink({to, children, ...props}: LinkProps) {
    const resolvedPath = useResolvedPath(to);
    const isActive = useMatch({ path: resolvedPath.pathname, end: true});
    return (
        <li className={isActive ? "active" : ""}>
            <Link to={to} {...props}>
                {children}
            </Link>
        </li>
    )
}

export default function Navbar() {
    return <nav>
        <Link to="/"></Link>
        <ul>
          <NavBarLink to='/'>About</NavBarLink>
          <NavBarLink to='mailto:valdirunars@gmail.com'>Get in Touch</NavBarLink>
          {/* <NavBarLink to='/contact'>Get in Touch</NavBarLink> */}
        </ul>
    </nav>
}

