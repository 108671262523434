import React from 'react';
import './App.css';
import Navbar from './Navbar';
import { Routes, Route } from 'react-router-dom';
import Home from './Home';
import { APIProvider } from './APIContext';
import { MouseAnimationProvider } from './MouseAnimationContext';

function App() {
  return (
    <MouseAnimationProvider>
      <APIProvider>
        <div className="App">
          <Navbar />

          <Routes>
            <Route path='/' element={<Home />}/>
            <Route path='/contact' element={<div />}/>
          </Routes>
        </div>
      </APIProvider>
    </MouseAnimationProvider>
  );
}

export default App;
