

export function calculateYearsSinceDate(date: Date): number {
    const monthDiff: number = Date.now() - date.getTime();

    const ageDt: Date = new Date(monthDiff);
    const year: number = ageDt.getUTCFullYear();

    return Math.abs(year - 1970);
}

export function calculateYearsAsADeveloper(): number {
    return calculateYearsSinceDate(new Date("2015/4/1"));
}

export function calculateMyAge(): number {
    return calculateYearsSinceDate(new Date("1993/12/10"));
}
