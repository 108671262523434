import './styles.css';
import { Project, Skill } from "../types";


type Props = {
    i: number;
    project: Project
}

function ProjectSkill({skill, isLastSkill}: {skill: Skill, isLastSkill: boolean}) {
    return <div className="tag">
        {skill.name}{!isLastSkill ? ',\u00A0 ' : ''}
    </div>;
}

export default function ProjectComponent({i, project}: Props) {
    const cardBody = <>
        <h4>
            {project.name || '?'}
        </h4>
        <img className='projectImage' src={`/img/project_${project.id}.png`} alt="image" />
        {project.skills && <div>
            <strong>Skills: </strong>
            {project.skills && (
                <div className="tagContainer">
                    {project.skills.map((s, idx) =>
                        <ProjectSkill
                            key={`${project.name}_${s.name}_${i}`}
                            isLastSkill={idx === ((project.skills || []).length - 1)}
                            skill={s}
                        />)}
                </div>
            )}
        </div>}
    </>
    return (
        project.url
            ? <a className="card" href={project.url}>{cardBody}</a>
            : <div className="card" >{cardBody}</div>
    );
}