import './styles.css';
import { useCallback, useEffect, useState } from 'react';
import QueryDialog from '../QueryDialog';
import CustomButton from '../CustomButton';
import { calculateMyAge, calculateYearsAsADeveloper } from '../utils';
import { useAPI } from '../APIContext';
import { useMouseAnimationContext } from '../MouseAnimationContext';
import Company from '../Company';
import { SocialIcon } from 'react-social-icons';
import Loader from '../Loader';
import Project from '../Project';
import Skill from '../Skill';
import Blog from '../BlogPost';
import ErrorDialog from '../ErrorDialog';
import Prism from 'prismjs';
import Editor from 'react-simple-code-editor';

import 'prismjs/components/prism-graphql';
import 'prismjs/themes/prism.css';

export default function Home() {
   
    const [queryDialogIsOpened, setQueryDialogIsOpened] = useState(false);
    
    const [isErrorDialogOpen, setIsErrorDialogOpen] = useState<boolean>(false);
    const {
        isLoading,
        error,

        companies,
        projects,
        skills,
        blogPosts,

        query,
        setQuery,
        resetGraphQLQuery,
        graphiQLURL,

        fetchData,
        
    } = useAPI();
    
    useEffect(() => {
        const shouldShowGraphQLError = typeof error !== 'undefined';
        setIsErrorDialogOpen(shouldShowGraphQLError);        
    }, [error])


    const {
        onMouseMove,
        onTouchMove,
        imgRef,
    } = useMouseAnimationContext();

    const onProceed = useCallback(() => {
        fetchData();
    }, [query]);

    const handleQueryChange = (text: string) => {
        // 👇️ access textarea value
        setQuery(text);
    };

    const infoTextComponents = (<>
        <p>My name is Thorvaldur Runarsson.</p>
        <p>I'm a {calculateMyAge()} year old <i>Full Stack Developer</i></p>
        <p>based in <strong>Reykjavik, Iceland</strong> with <strong>over {calculateYearsAsADeveloper()} years</strong> of experience in the field.</p>
        <div className="social">
            <CustomButton
                title="Let's work together"
                type="btnPrimary"
                id="letsWorkTogether"
                onClick={() => {
                    window.open("mailto:valdirunars@gmail.com");
                }}
            />
            
            <SocialIcon url="https://linkedin.com/in/valdirunars" />
            <SocialIcon url="https://medium.com/@valdirunars"/>

        </div>

    </>);

    return (
        <div onMouseMove={onMouseMove} onTouchMove={onTouchMove}>
            {error && (
                <ErrorDialog
                    isOpened={isErrorDialogOpen}
                    error={error}
                    title={error.name}
                    message={error.message}
                    onOKPressed={() => {
                        setQueryDialogIsOpened(true);
                    }}
                    onClose={() => setIsErrorDialogOpen(false)}
                ></ErrorDialog>
            )}

            <section id="introSection">
                <img ref={imgRef} className='memoji' src="/memoji/hello.png" alt="image" />
                <div className="speech-bubble container">

                    <header>
                        <h2>
                            Welcome to "Forritarinn"
                            <br/>
                            (e. The Programmer)
                        </h2>
                    </header>
                    {infoTextComponents}
                </div>
            </section>
            
            <div className="container">
                <p>P.S. Feel free to browse my experience via the GraphQL filters below!</p>
                <CustomButton type='btnPrimary' id="modifyFilters" title='Modify GraphQL' onClick={() => setQueryDialogIsOpened(true)}/>
            </div>
            {isLoading && <Loader></Loader>}
            {!isLoading && <section id="filterSection" className="container" >
                <QueryDialog
                    isOpened={queryDialogIsOpened}
                    onProceed={onProceed}
                    onClose={() => setQueryDialogIsOpened(false)}
                    onShouldReset={() => resetGraphQLQuery()}
                >
                    <h3>Modify GraphQL</h3>
                    <p>Alternatively, see <a href={graphiQLURL}>my GraphQL server's playground</a> for documentation and other things.</p>
                    <p>There's also <a href="https://quickref.me/graphql.html">this helpful GraphQL cheatsheet</a>.</p>
                    
                    <div className='syntaxHighlightEditorContainer'>
                        <Editor
                            highlight={code => Prism.highlight(code, Prism.languages['graphql'], 'graphql')}
                            onValueChange={handleQueryChange} value={query}
                            style={{
                                fontFamily: '"Fira code", "Fira Mono", monospace',
                                backgroundColor: '#efefef',
                            }}
                        />
                    </div>
                </QueryDialog>
                {companies && (
                    <article id='companies' className="cardContainer">
                        <div className="cardSectionContainer">
                            <h3>Companies I've Worked With</h3>
                            {companies.map((c, i) => <Company key={`${c.name}_${i}`} i={i} company={c} />)}
                        </div>
                    </article>
                )}
                {projects && (
                    <article id='projects' className="cardContainer">
                        <div className="cardSectionContainer">
                            <h3>Notable Projects I've worked on</h3>
                            {projects.map((p, i) => <Project key={`${p.name}_${i}`} i={i} project={p} />)}
                            
                        </div>
                    </article>
                )}

                {skills && (
                    <article id='skills' className="cardContainer">
                        <div className="cardSectionContainer">
                            <h3>Notable Skills Acquired <br/>(In no particular order)</h3>
                            {skills.map((s, i) => (
                                <Skill key={`${s.name}_${i}`} skill={s} /> 
                                
                            ))}
                        </div>
                    </article>
                )}

                {blogPosts && (
                    <article id='blogPosts' className="cardContainer">
                        <div className="cardSectionContainer">
                            <h3>Blog Posts</h3>
                            {blogPosts.map((b, i) => {
                                return <Blog key={`${b.name}_${i}`} i={i} blog={b} />
                            })}
                        </div>
                    </article>
                )}
            </section>}
        </div>
    );
}